/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react';
import { BookOutlined, FilterOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Layout,
  Spin,
  Button,
  Table,
  Input,
  Dropdown,
  Radio,
  Tag,
  Tooltip,
  Flex,
  Col,
} from 'antd';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { projectListContainerStyle } from './project-layout.styles';
import { ColorCategories } from '../../../iso/colors';
import { centeredHorizontallyFlexStyle } from '../../_shared/style';
import AskTuringPanel from '../dashboard/ask-turing-panel.component';
import { useSession } from '../../_shared/context';
import { logEvent, TrackableEvent } from '../../_shared/tracking/usage-tracker';
import {
  FormulationStatus,
  WorkspaceType,
  getProjectsQuery,
  usecreateIterationMutation,
  usegetProjectsLazyQuery,
  useupdateOneProjectMutation,
} from '../../../../__generated__/globalTypes';
import { css } from '@emotion/react';
import { ColumnType } from 'antd/lib/table';
import { ProjectSetupSteps } from '../exploration/constants';
import { userInfo } from 'os';
import { useDuplicateProject } from '../../network/services/project.service';
import { DuplicateProjectModal } from './duplicate-project/duplicate-project-modal';
import posthog from 'posthog-js';
const { Content } = Layout;
const { Search } = Input;

type Organization = NonNullable<getProjectsQuery['organization']>;
type FetchedProject = NonNullable<
  getProjectsQuery['organization']
>['projects'][0];

const createdAtFormatter = (
  createdBy:
    | {
      __typename?: 'User' | undefined;
      id: string;
      firstName?: string | null | undefined;
      lastName?: string | null | undefined;
      email: string;
    }
    | null
    | undefined
) => {
  if (!createdBy) {
    return;
  }
  const isPlaceholderUser = createdBy.email.includes('PLACE-HOLDER-USER');
  const displayText = isPlaceholderUser
    ? 'Turing Admin'
    : createdBy?.firstName
      ? `${createdBy?.firstName ?? ''} ${createdBy?.lastName ?? ''}`
      : `${createdBy?.email}`;
  return displayText;
};

interface ProjectListLayoutProps {
  onIndexLoad: () => void;
}

export const ProjectListLayoutV2: React.FC<ProjectListLayoutProps> = ({
  onIndexLoad
}) => {
  const navigate = useNavigate();

  const location = useLocation();
  useEffect(() => {
    if (location.pathname === '/') {
      onIndexLoad();
    }
  }, [location, onIndexLoad]);

  const {
    showAskTuring,
    user: currentUser,
    showProjectCardView,
    setShowProjectCardView,
  } = useSession();

  const [
    createIteration,
    { loading: createIterationLoading, error: createIterationError },
  ] = usecreateIterationMutation();

  const isSuperAdmin = currentUser?.role === 'SUPER_ADMIN';

  const duplicateMutation = useDuplicateProject()
  const { user } = useSession()
  const [getProjects, { loading, data, error }] = usegetProjectsLazyQuery();
  const [numberOfProjects, setNumberOfProjects] = useState<number>(0);
  const [searchValue, setSearchValue] = useState<string>('');
  const [sortBy, setSortBy] = useState<string>('updatedAt');
  const [showDisabledProjects, setShowDisabledProjects] = useState(false);
  const [isCopyModalVisible, setIsCopyModalVisible] = useState<boolean>(false);
  const [currentRecordProjId, setCurrentRecordProjId] = useState<string>('');
  const [projectList, setProjectList] = useState<Organization['projects']>([]);
  const [updateProject] = useupdateOneProjectMutation();
  const formattedTableData = projectList
    .map(
      (
        {
          name,
          id,
          isActive,
          description,
          key,
          createdBy,
          createdAt,
          updatedAt,
          iterations,
          formulations,
          lastSetupStep,
          mlAPImessage,
        },
        index
      ) => {
        let experimentCount = 0;
        for (const iteration of iterations) {
          if (iteration?.designs) {
            for (const design of iteration?.designs) {
              if (design?.product?.productVersion) {
                experimentCount =
                  experimentCount + design?.product?.productVersion.length;
              }
            }
          }
        }
        if (experimentCount > 0) {
          // Add in the existing experiments if designs returned results
          experimentCount += 10;
        }
        return {
          name,
          id,
          isActive,
          description,
          keyName: key,
          experiments: experimentCount,
          createdBy,
          createdAt: new Date(createdAt).getTime(),
          updatedAt: new Date(updatedAt).getTime(),
          color: ColorCategories[index] ?? '#3457d5',
          iterations,
          formulations,
          lastSetupStep,
          mlAPImessage,
        };
      }
    )
    .filter(p => p.isActive === !showDisabledProjects)
    .sort((a, b) => {
      if (sortBy === 'name') {
        return a.name.localeCompare(b.name);
      }
      if (sortBy === 'createdAt') {
        return b.createdAt - a.createdAt;
      }
      if (sortBy === 'updatedAt') {
        return b.updatedAt - a.updatedAt;
      }
    });

  const [tableData, setTableData] = useState(formattedTableData);
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const [duplicateModaldata, setDuplicateModaldata] = useState<{
    openModal: boolean,
    projectId: string,
    orgId: string
  }>({
    openModal: false,
    projectId: '',
    orgId: ''
  });

  posthog.identify(
    user?.id,  // Replace 'distinct_id' with your user's unique identifier
    { email: user?.email, name: `${user?.firstName} ${user?.lastName}` } // optional: set additional person properties
  );

  useEffect(() => {
    getProjects();
  }, []);

  useEffect(() => {
    setTableData(formattedTableData);
  }, [projectList, sortBy]);

  useEffect(() => {
    if (searchValue === '') {
      setTableData(formattedTableData);
    } else {
      let searchedData = formattedTableData.filter(
        project =>
          project.name.toLowerCase().includes(searchValue?.toLowerCase()) ||
          project.keyName.toLowerCase().includes(searchValue?.toLowerCase()) ||
          (project.createdBy?.firstName + ' ' + project.createdBy?.lastName)
            .toLowerCase()
            .includes(searchValue?.toLowerCase())
      );
      setTableData(searchedData);
    }
  }, [searchValue]);

  useEffect(() => {
    if (data) {
      const unsortedProjects = data?.organization?.projects
        ? [...data.organization.projects]
        : [];

      const sorted = unsortedProjects
        .filter(p => (showDisabledProjects ? true : p.isActive))
        .sort((a, b) => a.name.localeCompare(b.name));
      setNumberOfProjects(sorted.length);
      setProjectList(sorted);
    }
  }, [data, showDisabledProjects]);

  if (error) {
    //TODO: Error state Can we get a global error renderer?
    return (
      <div css={centeredHorizontallyFlexStyle}>
        Something went wrong, refresh your screen and try again.
      </div>
    );
  }

  if (loading) {
    return <Spin />;
  }

  const goToProject = async (
    record: Organization['projects'][0],
    forceShowEditScreen?: boolean
  ) => {
    logEvent(TrackableEvent.PROJECT_BUTTON_CLICKED, {
      projectId: record.id,
    });

    const hasPinnedFormulations = record.formulations.find(
      formulation => formulation.status === FormulationStatus.PINNED
    );

    const project = data?.organization?.projects.find(
      project => project.id === record.id
    );

    const projectMissingExistingExploration =
      project?.iterations.filter(
        iteration => iteration.type === WorkspaceType.EXPLORATION
      ).length === 0;

    let newIterationId: string | undefined;

    if (project?.lastSetupStep === 'COMPLETE') {
      if (project && projectMissingExistingExploration) {
        let { data } = await createIteration({
          variables: {
            data: {
              name: 'Untitled Exploration',
              projectId: project.id,
              type: WorkspaceType.EXPLORATION,
            },
          },
        });
        newIterationId = data?.createOneIteration.id;
      } else {
        newIterationId = project?.iterations?.[project?.iterations.length - 1].id;
      }
    }

    if (record.lastSetupStep && record.lastSetupStep == 'BUILDING_MODEL')
      return;

    if (record.lastSetupStep && record.lastSetupStep !== 'COMPLETE') {
      navigate(`/project/new/${record.id}`);
    } else {
      if (hasPinnedFormulations) {
        navigate(`/project/${record.id}/experiments`);
      } else {
        navigate(`/project/${record.id}/explore/${newIterationId}`);
      }
    }
  };

  const disableProject = async (id: string, isActive: boolean) => {
    await updateProject({
      variables: {
        data: {
          isActive: !isActive,
        },
        where: { id },
      },
    });
    await getProjects();
  };

  const onMenuClick = (key: string, record: any) => {
    if (key === 'archive' || key === 'unarchive') {
      disableProject(record.id, record.isActive);
    }

    if (key === 'summary') {
      navigate(`/summary/${record.id}`)
    }

    if (key === 'edit') {
      navigate(`/project/edit/${record.id}`);
    }

    if (key === 'metadata') {
      window.open(
        `/api/v2/metadata/${record.id}`,
        '_blank',
        'noopener,noreferrer'
      );
    }
    if (key === 'scm') {
      window.open(
        `/api/v2/org/${currentUser?.organizationId}/scm/${record.id}`,
        '_blank',
        'noopener,noreferrer'
      );
    }

    if (key == 'duplicate') {

      if (record.lastSetupStep === 'COMPLETE') {
        setCurrentRecordProjId(record.id)
        console.log("aaaaaa", isCopyModalVisible)
        setIsCopyModalVisible(true)
        console.log("aaaaaa", isCopyModalVisible)
      }
      else {
        duplicateAProject(currentUser?.organizationId ?? '', record.id, false)
      }
    }

    if (key === 'summary') {
      navigate(`/summary/${record.id}`);
    }
  };

  const duplicateAProject = (
    orgId: string,
    projectId: string,
    duplicateWithModel: boolean
  ) => {
    duplicateMutation.mutate({
      orgId,
      projectId,
      duplicateWithModel
    }, {
      onSuccess: async (response) => {
        handleCloseDuplicateProjectModal()
        await getProjects();
      }
    })
  }

  const handleCloseDuplicateProjectModal = () => {
    setIsCopyModalVisible(false)
  }

  const tableColumns: ColumnType<Record<string, any>>[] = [
    {
      key: 'id',
      title: 'ID',
      dataIndex: 'id',
      width: 100,
      onCell: record => ({
        onClick: () => {
          goToProject(record);
        },
      }),
      render: (value: string, record: any) => {
        return <div>{record.keyName}</div>;
      },
    },
    {
      key: 'name',
      title: 'Name',
      dataIndex: 'name',
      width: 250,
      onCell: record => ({
        onClick: () => {
          goToProject(record);
        },
      }),
      render: (value: string, record: any) => {
        return (
          <div>
            <span>{value}</span>
            <p style={{ fontSize: 11 }}>{record.description}</p>
          </div>
        );
      },
    },
    {
      key: 'experiments',
      title: 'Experiments',
      dataIndex: 'experiments',
      render: (value: string, record: any) => {
        return <span>{value}</span>;
      },
    },
    {
      key: 'createdBy',
      title: 'Owner',
      dataIndex: 'createdBy',
      onCell: record => ({
        onClick: () => {
          goToProject(record);
        },
      }),
      render: (createdBy, record) => {
        let displayText = createdAtFormatter(createdBy);
        return (
          <div
            css={css`
              text-wrap: nowrap;
            `}
          >
            {displayText}
          </div>
        );
      },
    },
    {
      key: 'createdAt',
      title: 'Created',
      dataIndex: 'createdAt',
      onCell: record => ({
        onClick: () => {
          goToProject(record);
        },
      }),
      render: (createdAt: string, record: any) => {
        return new Date(createdAt).toLocaleDateString();
      },
    },
    {
      key: 'updatedAt',
      title: 'Updated',
      dataIndex: 'updatedAt',
      onCell: record => ({
        onClick: () => {
          goToProject(record);
        },
      }),
      render: (createdAt: string, record: any) => {
        return new Date(createdAt).toLocaleDateString();
      },
    },
    {
      key: 'status',
      title: 'Status',
      dataIndex: 'lastSetupStep',
      onCell: record => ({
        onClick: () => {
          goToProject(record);
        },
      }),
      render: (lastSetupStep: string, record: any) => {
        return (
          <>
            {console.log(record)}
            {lastSetupStep &&
              (lastSetupStep == ProjectSetupSteps.PROJECT_INFO ||
                lastSetupStep == ProjectSetupSteps.INPUT_OUTCOMES ||
                lastSetupStep == ProjectSetupSteps.NON_NEGOTIABLE_CONSTRAINS ||
                lastSetupStep == ProjectSetupSteps.EDIT_RAW_DATA ||
                lastSetupStep == ProjectSetupSteps.UPLOAD_DATA ||
                lastSetupStep == ProjectSetupSteps.EDIT_RAW_INPUT_OUTCOMES) && (
                <Tag color="blue">DRAFT</Tag>
              )}
            {lastSetupStep && lastSetupStep == 'ERROR' && (
              <Tooltip
                title={`Oops! Something went wrong with the project setup. ${record.mlAPImessage}`}
              >
                <Tag color="red">Failed</Tag>
              </Tooltip>
            )}
            {lastSetupStep && lastSetupStep == 'BUILDING_MODEL' && (
              <Tag color="gold">In-progress</Tag>
            )}
            {(lastSetupStep == null || lastSetupStep == 'COMPLETE') && (
              <Tag color="green">Ready</Tag>
            )}
          </>
        );
      },
    },
    ...(isSuperAdmin
      ? [
        {
          key: 'action',
          title: '',
          dataIndex: 'action',
          width: '100px',
          render: (_: string, record: any) => {
            const { isActive, id } = record;
            const item = isActive
              ? {
                key: 'archive',
                label: 'Archive',
              }
              : {
                key: 'unarchive',
                label: 'Unarchive',
              };
            const items = [];
            items.push(item);

            if (record.lastSetupStep !== 'ERROR') {
              items.push({
                key: 'duplicate',
                label: 'Duplicate Project',
              });
            }

            if (record.lastSetupStep === 'COMPLETE') {
              items.push({
                key: 'edit',
                label: 'Edit Project',
              });

              // items.push({
              //   key: 'summary',
              //   label: 'Project Summary',
              // });
            }

            // if (record.lastSetupStep == 'COMPLETE' || record.lastSetupStep == 'ERROR') {
            //   items.push({
            //     key: 'metadata',
            //     label: 'View Metadata',
            //   })
            //   items.push({
            //     key: 'scm',
            //     label: 'View SCM'
            //   })
            // }
            return (
              <div
                css={css`
                    text-wrap: nowrap;
                  `}
              >
                <Dropdown
                  menu={{
                    items: items,
                    onClick: ({ key }) => onMenuClick(key, record),
                  }}
                >
                  <span style={{ fontWeight: 600, fontSize: 18 }}>...</span>
                </Dropdown>
              </div>
            );
          },
        },
      ]
      : [
        {
          key: 'action',
          title: '',
          dataIndex: 'action',
          width: '100px',
          render: (_: string, record: any) => {
            if (record.lastSetupStep === 'COMPLETE') {
              return (
                <div
                  css={css`
                      text-wrap: nowrap;
                    `}
                >
                  <Dropdown
                    menu={{
                      items: [
                        {
                          key: 'edit',
                          label: 'Edit Project',
                        },
                      ],
                      onClick: ({ key }) => onMenuClick(key, record),
                    }}
                  >
                    <span style={{ fontWeight: 600, fontSize: 18 }}>...</span>
                  </Dropdown>
                </div>
              );
            }
          },
        },
      ]),
  ];

  return (
    <Content css={projectListContainerStyle}>
      <div
        css={css`
          display: flex;
          justify-content: center;
          flex-direction: column;
          margin-left: 5%;
          margin-right: 5%;
          @media only screen and (max-width: 1350px) {
            margin-left: 3%;
            margin-right: 3%;
          }
          @media only screen and (max-width: 992px) {
            margin-left: 2%;
            margin-right: 2%;
          }
          @media only screen and (max-width: 768px) {
            margin-left: 1%;
            margin-right: 1%;
          }
          .project-card:hover {
            outline: #cccccc solid 2px;
          }
          /* .active-project-select,
           .ant-select-selector {
             background-color: transparent !important;
             border: none !important;
           } */
          .active-project-select:focus,
          .ant-select-selector:focus {
            border: none !important;
          }
          .active-project-select {
            font-weight: 500;
            font-size: 20px;
          }
          .sort-by-select {
            border: 1px solid #d9d9d9;
            border-radius: 4px;
            background-color: #fff;
            font-weight: 350 !important;
            font-size: 14px !important;
          }
          .ant-select-arrow {
            color: #011;
          }
        `}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '20px',
          }}
        >
          <span style={{ fontSize: 24, fontWeight: 'bold' }}>Projects</span>
          {isSuperAdmin && (
            <Flex>
              <Button
                title="Add New Project"
                type="primary"
                icon={<PlusOutlined />}
              >
                <Link to={`/project/new`}>New Project</Link>
              </Button>
              {/* <Button
                title="See cross project library"
                type="primary"
                icon={<BookOutlined />}
                style={{ marginLeft: '1rem' }}
              >
                <Link to={`/cross-project-library/${currentUser.id}`}>Ingredient Library</Link>
              </Button> */}
            </Flex>
          )}
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '20px',
            gap: 10,
          }}
        >
          {/* <div>
            <Select
              className="active-project-select"
              onChange={(value: string) =>
                value === 'active'
                  ? setShowDisabledProjects(false)
                  : setShowDisabledProjects(true)
              }
              value={showDisabledProjects ? 'archived' : 'active'}
              options={
                isSuperAdmin
                  ? [
                      { value: 'active', label: 'Active Projects' },
                      { value: 'archived', label: 'Archived Projects' },
                    ]
                  : [{ value: 'active', label: 'Active Projects' }]
              }
            />
          </div> */}
          <div> {numberOfProjects} Projects</div>
          <div>
            <Search
              style={{ marginRight: 10 }}
              allowClear
              placeholder="Search projects..."
              onChange={e => setSearchValue(e.target.value)}
              css={css`
                width: 350px;
                @media only screen and (max-width: 1350px) {
                  width: 300px;
                }
                @media only screen and (max-width: 992px) {
                  width: 200px;
                }
                @media only screen and (max-width: 768px) {
                  width: 100px;
                }
              `}
            />

            <Dropdown
              menu={{
                items: [
                  {
                    key: 'createdAt',
                    label: (
                      <>
                        <Radio checked={sortBy === 'createdAt'} />
                        Date Created (Newest)
                      </>
                    ),
                  },
                  {
                    key: 'updatedAt',
                    label: (
                      <>
                        <Radio checked={sortBy === 'updatedAt'} />
                        Most Recent Activity
                      </>
                    ),
                  },
                  {
                    key: 'name',
                    label: (
                      <>
                        <Radio checked={sortBy === 'name'} />
                        Name
                      </>
                    ),
                  },
                ],
                onClick: e => setSortBy(e.key),
              }}
              trigger={['click']}
            >
              <Button icon={<FilterOutlined />}>Sort By</Button>
            </Dropdown>
          </div>
        </div>
        <Table
          className="project-table"
          style={{
            cursor: 'pointer',
          }}
          pagination={{
            showTotal: total => {
              setNumberOfProjects(total);
              return '';
            },
            position: ['bottomLeft'],
          }}
          rowKey={rec => rec.id}
          columns={tableColumns}
          dataSource={tableData}
        />
        {showAskTuring && (
          <Col flex="300px">
            <AskTuringPanel />
          </Col>
        )}
      </div>
      {
        isCopyModalVisible && (
          <DuplicateProjectModal openModal={true}
            orgId={currentUser?.organizationId ?? ''}
            projectId={currentRecordProjId}
            copyProjectMutation={duplicateAProject}
            onClose={handleCloseDuplicateProjectModal} />
        )
      }

    </Content>
  );
};
