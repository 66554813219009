import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import {
  Constraint,
  ConstraintInputType,
  ConstraintType,
  IngredientList,
  projectByIdQuery,
} from '../../../../../../../__generated__/globalTypes';
import { useIngredients } from '../../../../../_shared/hooks';
import { Button, Divider, Input, InputNumber, Radio, Select } from 'antd';
import {
  IngredientSearch,
  IngredientAndCompositionSelectValue,
} from '../../../../../_shared/components/input/ingredient-search.component';
import { useScenarioDetail } from '../../../../../_shared/context/scenario-detail-context';
import { useSession } from '../../../../../_shared/context';
import { validateConstraint } from '../../../../../components/workspaces/adaptive-learning/design-validation';
import { css } from '@emotion/css';
import { WarningOutlined } from '@ant-design/icons';
import { useDesign } from '../../../../../_shared/context/design-context';
import { useCreateConstraint } from '../../../../../network/services/constraint.service';
import './project-non-negotiable-const-rows.less';
import { emptyConstraint } from '../project-non-negotiable-constraints';
import { VariableType } from '@prisma/client';
import { set } from 'lodash';
import { validate } from 'graphql';
import { Label } from 'recharts';
import { format } from 'path';
import { IngredientGroup } from '../../../../../components/project/ingredients-group/ingredients-group';
import { ConstraintForm } from '../../../../../components/exploration/exploration-contraint-setting-v2/constraint-form/constraint-form';
import { ConstraintComposition } from '../../../../../components/exploration/exploration-contraint-setting-v2/constraint-composition/constraint-composition';

export const formatConstraintText = (
  constraint: ConstraintInputType,
  ingredientByName: Map<string, IngredientList>,
  ingredientCompositions?: NonNullable<
    NonNullable<projectByIdQuery['project']>['ingredientComposition']
  >
) => {
  let { constraintType } = constraint;
  let constraintText: string | undefined;
  let constraintDescription: string | undefined;
  let ingUnit;
  let ingName;
  switch (constraintType) {
    case ConstraintType.AMOUNT:
      const composition = ingredientCompositions?.find(
        composition => composition.id === constraint.ingredientCompositionId
      );
      ingName = composition?.name;
      constraintText = `${constraint.name ? constraint.name : ingName}`;
      constraintDescription = `Between ${constraint.lowerBounds ?? ''} and ${constraint.upperBounds ?? ''
        }`;
      break;
    case ConstraintType.RANGE:
      ingName = constraint?.coefficients?.[0]?.name ?? '';
      ingUnit = ingredientByName.get(ingName)?.unit ?? '';
      constraintText = `${constraint.name ? constraint.name : ingName}`;
      constraintDescription = `Between ${constraint.lowerBounds ?? ''
        }${ingUnit} and ${constraint.upperBounds ?? ''}${ingUnit}`;
      break;
    case ConstraintType.EQUALITY:
      ingName = constraint?.values?.[0]?.name ?? '';
      ingUnit = ingredientByName.get(ingName)?.unit ?? '';
      constraintText = `${constraint.name ? constraint.name : ingName}`;
      constraintDescription =
        constraint?.values?.[0]?.value &&
        `Target value of ${constraint?.values?.[0]?.value ?? ''}${ingUnit}`;
      break;
    case ConstraintType.COUNT:
      // CATEGORY CONSTRAINT
      constraintText = `${constraint.name ? constraint.name : 'Unnamed'}`;
      constraintDescription = `At least ${constraint.lowerBounds ?? ''
        } and at most ${constraint.upperBounds ?? ''}`;
      break;
  }
  return {
    text: constraintText ?? '',
    description: constraintDescription ?? '',
  };
};

export const ProjectNonNegotiableConstraintsRow = ({
  constraint,
  index,
  defaultShowBody,
  onCancel,
  onSave,
  ingredientsList,
  projId,
  onConstrainAdd,
}: {
  constraint: Constraint;
  index: number;
  defaultShowBody?: boolean;
  onCancel?: (constraint: Constraint) => void | null;
  onSave?: (constraint: Constraint) => void | null;
  ingredientsList?: any;
  projId?: string;
  onConstrainAdd: (constraint: any) => void;
}) => {
  const { currentProject } = useSession();
  const [showBody, setShowBody] = useState(defaultShowBody ?? false);
  const {
    removeConstraint,
    constraints,
    saveConstraints,
  } = useScenarioDetail();
  const { quickDesignIsRunning, runQuickDesign } = useDesign();
  const [constraintToEdit, setConstraintToEdit] = useState(constraint);
  const [errorMessage, setErrorMessage] = useState('');
  const [ingredientsListProp, setIngredientsListProp] = useState([]);
  const { ingredientByName } = useIngredients();
  useEffect(() => {
    setErrorMessage('');
  }, [constraintToEdit]);
  useEffect(() => {
    setConstraintToEdit({ ...constraint });
  }, [constraint]);

  useEffect(() => {
    if (ingredientsList) {
      setIngredientsListProp(ingredientsList);
    }
  }, [ingredientsList]);

  const { user } = useSession();
  const [groupId, setGroupId] = useState<string | undefined>();
  const [compositionId, setCompositionId] = useState<string | undefined>();
  const saveConstraintV2 = useCreateConstraint();
  const handleSaveConstraint = async () => {
    let validationResponse;

    if (checkIngredientType()) {
      validationResponse = {
        isValid: true,
        description: '',
      };
    } else {
      validationResponse = validateConstraint(
        constraintToEdit,
        ingredientsListProp
      );
    }

    if (validationResponse.isValid) {
      let constraintsToSave;
      if (constraintToEdit.id) {
        // We don't want to acidently pass the old version of the constraint with the same ID
        let otherConstraints = constraints.filter(
          constraint => constraint.id !== constraintToEdit.id
        );
        constraintsToSave = [...otherConstraints, constraintToEdit];
      } else {
        constraintsToSave = [constraintToEdit];
        console.log(constraintsToSave);
      }

      let returnedConstraints = saveConstraintV2.mutate(
        {
          organizationId: user?.organizationId,
          projectId: projId,
          constraint: constraintToEdit,
        },
        {
          onSuccess: async response => {
            onConstrainAdd(response.data);
          },
        }
      );
      setShowBody(true);
      setConstraintToEdit(emptyConstraint);
    } else {
      setErrorMessage(validationResponse.description);
    }
  };

  const handleConstraintDelete = async () => {
    await removeConstraint(constraintToEdit);
  };

  const handleCancel = () => {
    setShowBody(false);
    setConstraintToEdit(constraint);
    onCancel && onCancel(constraintToEdit);
  };

  const removeIngredient = (i: string) => {
    setConstraintToEdit({
      ...constraintToEdit,
      variables: constraintToEdit?.variables?.filter(val => val !== i),
    });
  };

  const constraintText = formatConstraintText(
    constraintToEdit,
    ingredientByName as Map<string, IngredientList>,
    currentProject?.ingredientComposition
  );

  // ? We want to make sure all our compositions have at least one ingredient with a non 0%
  // ? Otherwise the ML API will fail the jobs since the constraint will have an empty coeffiecients array
  const compositionsWithNonZeroValues = useMemo(() => {
    const setOfCompositions = new Set();
    currentProject?.ingredientList.forEach(ingredient => {
      ingredient.ingredientCompositions?.forEach(comp => {
        if (comp.value > 0) {
          setOfCompositions.add(comp.ingredientCompositionId);
        }
      });
    });
    return setOfCompositions;
  }, [currentProject?.ingredientComposition, currentProject?.ingredientList]);

  const addComposition = (composition: string) => {
    const clonedConstraint = { ...constraintToEdit };
    const compObj = currentProject?.ingredientComposition.find(
      ingComposition => ingComposition.name === composition
    );

    clonedConstraint.ingredientCompositionId = compObj?.id;
    clonedConstraint.coefficients = [];
    // Find the list of ingredients in the composition
    const compIngredients = currentProject?.ingredientList
      .map(ingList => {
        const ingredientComp = ingList.ingredientCompositions.find(ic => {
          return ic.ingredientCompositionId === compObj?.id && ic.value > 0;
        });

        if (ingredientComp) {
          return ingList;
        }
      })
      .filter(Boolean);
    //Update the contraints coefficients for each ingredient
    compIngredients?.forEach(ci => {
      const compositionVal = ci?.ingredientCompositions.find(
        ing => ing.ingredientCompositionId === compObj?.id
      )?.value;

      if (compositionVal)
        clonedConstraint.coefficients?.push({
          name: ci?.ingredient.name,
          value: compositionVal / 100,
        });

      setConstraintToEdit({
        ...clonedConstraint,
        constraintType: ConstraintType.AMOUNT,
      });
    });
  };

  //TODO
  useEffect(() => {
    if (constraintToEdit) {
      setCompositionId(constraintToEdit.ingredientCompositionId as string);
      setGroupId(constraintToEdit.ingredientGroupId as string);
    }
    console.log(constraintToEdit);
  }, [constraintToEdit]);

  const filterIngredients = () => {
    return ingredientsList.filter(
      (e: any) =>
        e.type !== VariableType.CATEGORICAL && e.type !== VariableType.ORDINAL
    );
  };

  const checkIngredientType = () => {
    if (
      constraintToEdit &&
      constraintToEdit.values &&
      constraintToEdit.values.length > 0
    ) {
      const ingredient = ingredientsList.find((e: any) => {
        return e.ingredient.name === constraintToEdit?.values?.[0].name;
      });
      return (
        ingredient &&
        (ingredient.type === VariableType.CATEGORICAL ||
          ingredient.type === VariableType.ORDINAL)
      );
    }
    return false;
  };

  const formatOptionsNominalValues = () => {
    const ingredient = ingredientsList.find((e: any) => {
      return e.ingredient.name === constraintToEdit?.values?.[0].name;
    });

    if (ingredient) {
      const values = ingredient.values;
      const formattedValues = values.map((v: any) => {
        return { value: v, label: v };
      });
      return formattedValues;
    }
    return [];
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignContent: 'center',
          flexDirection: 'column',
          paddingLeft: 5,
          paddingRight: 5,
        }}
      >
        {index === 0 && <Divider style={{ marginTop: 8, marginBottom: 8 }} />}

        <div
          onClick={() => setShowBody(!showBody)}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ color: '#505C65', fontWeight: 400 }}>
            {constraintText.text}
          </div>
          <div style={{ color: '#505C65', fontWeight: 400 }}>
            <span style={{ marginRight: 5 }}>
              {errorMessage && <WarningOutlined style={{ color: 'red' }} />}
            </span>
            {constraintText.description}
          </div>
        </div>
        <div>
          <Radio.Group
            style={{ marginTop: 15, marginBottom: 15 }}
            value={
              constraintToEdit.ingredientGroupId
                ? 'IngredientGroup'
                : constraintToEdit.ingredientCompositionId
                  ? 'Composition'
                  : constraintToEdit.constraintType
            }
            options={[
              { label: 'Value', value: ConstraintType.EQUALITY },
              {
                label: 'Range',
                value: constraintToEdit.ingredientCompositionId
                  ? ConstraintType.AMOUNT
                  : ConstraintType.RANGE,
              },
              { label: 'Category', value: ConstraintType.COUNT },
              { label: 'Ingredient Group', value: 'IngredientGroup' },
              { label: 'Composition', value: 'Composition' },
            ]}
            optionType="button"
            onChange={e => {
              if (e.target.value === ConstraintType.EQUALITY) {
                setConstraintToEdit({
                  ...constraintToEdit,
                  ingredientGroupId: null,
                  ingredientCompositionId: null,
                  coefficients: [],
                  variables: [],
                  constraintType: e.target.value,
                });
              }
              if (e.target.value === ConstraintType.COUNT) {
                setConstraintToEdit({
                  ...constraintToEdit,
                  ingredientGroupId: null,
                  ingredientCompositionId: null,
                  coefficients: [],
                  values: [],
                  constraintType: e.target.value,
                });
              }
              if (
                e.target.value === ConstraintType.AMOUNT ||
                e.target.value === ConstraintType.RANGE
              ) {
                setConstraintToEdit({
                  ...constraintToEdit,
                  ingredientGroupId: null,
                  ingredientCompositionId: null,
                  values: [],
                  variables: [],
                  constraintType: e.target.value,
                });
              }
              if (e.target.value === 'IngredientGroup') {
                setConstraintToEdit({
                  ...constraintToEdit,
                  values: [],
                  variables: [],
                  coefficients: [{ operator: '>' }],
                  constraintType: ConstraintType.RANGE,
                  ingredientGroupId: groupId ? groupId : 'dummyGroupId',
                  ingredientCompositionId: null,
                });
              }
              if (e.target.value === 'Composition') {
                setConstraintToEdit({
                  ...constraintToEdit,
                  values: [],
                  variables: [],
                  coefficients: [{ operator: '>' }],
                  constraintType: ConstraintType.RANGE,
                  ingredientGroupId: null,
                  ingredientCompositionId: compositionId
                    ? compositionId
                    : 'dummyComposition',
                });
              }
            }}
            buttonStyle="solid"
          />

          {!(
            constraintToEdit.ingredientGroupId ||
            constraintToEdit.ingredientCompositionId
          ) &&
            constraintToEdit.constraintType === ConstraintType.EQUALITY && (
              <div style={{ display: 'block' }}>
                <div>
                  <span>Name</span>
                  <Input
                    value={constraintToEdit.name ?? ''}
                    onChange={e =>
                      setConstraintToEdit({
                        ...constraintToEdit,
                        name: e.target.value,
                      })
                    }
                  />
                </div>
                <div>
                  <IngredientSearch
                    ingredients={ingredientsListProp}
                    defaultValue={constraintToEdit?.values?.[0]?.name}
                    className="constraint-select"
                    onSelect={e => {
                      const existingValue = constraintToEdit?.values?.[0] ?? {};
                      setConstraintToEdit({
                        ...constraintToEdit,
                        ingredientCompositionId: null,
                        coefficients: [],
                        values: [{ ...existingValue, name: e }],
                      });
                    }}
                  />
                </div>
                <div>
                  {!constraintToEdit.ingredientGroupId &&
                    constraintToEdit.constraintType === ConstraintType.EQUALITY &&
                    checkIngredientType() ? (
                    <Select
                      style={{ width: '100%' }}
                      options={formatOptionsNominalValues()}
                      placeholder=""
                      // value={constraintToEdit?.values?.[0]?.value}
                      onChange={e => {
                        const existingValue =
                          constraintToEdit?.values?.[0] ?? {};
                        setConstraintToEdit({
                          ...constraintToEdit,
                          values: [{ ...existingValue, value: e }],
                        });
                      }}
                    />
                  ) : (
                    <InputNumber
                      // value={constraintToEdit?.values?.[0]?.value}
                      style={{ width: '100%' }}
                      placeholder={(() => {
                        const ing = ingredientByName.get(
                          constraintToEdit?.values?.[0]?.name
                        );
                        return ing
                          ? `${ing?.lowerLimit} - ${ing?.upperLimit}`
                          : '';
                      })()}
                      onChange={e => {
                        const existingValue =
                          constraintToEdit?.values?.[0] ?? {};
                        setConstraintToEdit({
                          ...constraintToEdit,
                          values: [{ ...existingValue, value: e }],
                        });
                      }}
                    />
                  )}
                  {/* <InputNumber
                  value={constraintToEdit?.values?.[0]?.value}
                  style={{ width: '100%' }}
                  placeholder={(() => {
                    const ing = ingredientByName.get(
                      constraintToEdit?.values?.[0]?.name
                    );
                    return ing ? `${ing?.lowerLimit} - ${ing?.upperLimit}` : '';
                  })()}
                  onChange={e => {
                    const existingValue = constraintToEdit?.values?.[0] ?? {};
                    setConstraintToEdit({
                      ...constraintToEdit,
                      values: [{ ...existingValue, value: e }],
                    });
                  }}
                /> */}
                  {/* {valueConstraintInput} */}
                </div>
              </div>
            )}
          {!(
            constraintToEdit.ingredientGroupId ||
            constraintToEdit.ingredientCompositionId
          ) &&
            (constraintToEdit.constraintType === ConstraintType.RANGE ||
              constraintToEdit.constraintType === ConstraintType.AMOUNT) && (
              <div style={{ display: 'block' }}>
                <div>
                  <span>Name</span>
                  <Input
                    value={constraintToEdit.name ?? ''}
                    onChange={e =>
                      setConstraintToEdit({
                        ...constraintToEdit,
                        name: e.target.value,
                      })
                    }
                  />
                </div>
                <div>
                  <IngredientSearch
                    setValueAsJson={true}
                    ingredients={filterIngredients()}
                    defaultValue={
                      constraintToEdit?.ingredientCompositionId
                        ? JSON.stringify({
                          type: 'composition',
                          name: currentProject?.ingredientComposition?.find(
                            comp =>
                              comp.id ===
                              constraintToEdit?.ingredientCompositionId
                          )?.name,
                        })
                        : constraintToEdit?.coefficients?.[0]?.name
                    }
                    onSelect={e => {
                      const {
                        type,
                        name,
                      }: IngredientAndCompositionSelectValue = JSON.parse(e);
                      if (type === 'ingredient') {
                        setConstraintToEdit({
                          ...constraintToEdit,
                          ingredientCompositionId: null,
                          constraintType: ConstraintType.RANGE,
                          coefficients: [{ name: name, value: 1 }],
                        });
                      }
                      if (type === 'composition') {
                        addComposition(name);
                      }
                    }}
                    ingredientCompositions={currentProject?.ingredientComposition?.filter(
                      composition =>
                        compositionsWithNonZeroValues.has(composition.id)
                    )}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    gap: 15,
                  }}
                >
                  <div>
                    <span
                      style={{
                        display: 'block',
                        color: '#7C858C',
                        paddingBottom: 5,
                      }}
                    >
                      From
                    </span>
                    <InputNumber
                      style={{ width: '100%' }}
                      placeholder={String(
                        ingredientByName.get(
                          constraintToEdit?.coefficients?.[0]?.name
                        )?.lowerLimit ?? ''
                      )}
                      value={constraintToEdit?.lowerBounds}
                      onChange={e =>
                        e !== null &&
                        setConstraintToEdit({
                          ...constraintToEdit,
                          lowerBounds: Number(e),
                        })
                      }
                    />
                  </div>

                  <div>
                    <span
                      style={{
                        display: 'block',
                        color: '#7C858C',
                        paddingBottom: 5,
                        width: '100%',
                      }}
                    >
                      To
                    </span>
                    <InputNumber
                      style={{ width: '100%' }}
                      value={constraintToEdit?.upperBounds}
                      placeholder={String(
                        ingredientByName.get(
                          constraintToEdit?.coefficients?.[0]?.name
                        )?.upperLimit ?? ''
                      )}
                      onChange={e =>
                        e !== null &&
                        setConstraintToEdit({
                          ...constraintToEdit,
                          upperBounds: Number(e),
                        })
                      }
                    />
                  </div>
                </div>
              </div>
            )}
          {!(
            constraintToEdit.ingredientGroupId ||
            constraintToEdit.ingredientCompositionId
          ) &&
            constraintToEdit.constraintType === ConstraintType.COUNT && (
              <div style={{ display: 'block' }}>
                <div>
                  <span>Name</span>
                  <Input
                    value={constraintToEdit.name ?? ''}
                    onChange={e =>
                      setConstraintToEdit({
                        ...constraintToEdit,
                        name: e.target.value,
                      })
                    }
                  />
                </div>
                <div>
                  <IngredientSearch
                    onSelect={(i: string) => {
                      setConstraintToEdit({
                        ...constraintToEdit,
                        ingredientCompositionId: null,
                        coefficients: [],
                        variables: [...constraintToEdit?.variables!, i],
                      });
                    }}
                    onDeselect={removeIngredient}
                    ingredients={ingredientsListProp}
                    defaultValue={constraintToEdit?.variables?.map(c => ({
                      label: c,
                      value: c,
                    }))}
                    mode="multiple"
                    additionalCss={css`
                      .ant-select-selection-item {
                        background: #e6f2ff;
                        color: #017aff;
                        margin: 8px;
                      }
                    `}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    gap: 15,
                  }}
                >
                  <div>
                    <span
                      style={{
                        display: 'block',
                        color: '#7C858C',
                        paddingBottom: 5,
                      }}
                    >
                      At Least
                    </span>
                    <InputNumber
                      style={{ width: '100%' }}
                      value={constraintToEdit?.lowerBounds}
                      onChange={e =>
                        e !== null &&
                        setConstraintToEdit({
                          ...constraintToEdit,
                          lowerBounds: Number(e),
                        })
                      }
                    />
                  </div>

                  <div>
                    <span
                      style={{
                        display: 'block',
                        color: '#7C858C',
                        paddingBottom: 5,
                        width: '100%',
                      }}
                    >
                      At Most
                    </span>
                    <InputNumber
                      style={{ width: '100%' }}
                      value={constraintToEdit?.upperBounds}
                      onChange={e =>
                        e !== null &&
                        setConstraintToEdit({
                          ...constraintToEdit,
                          upperBounds: Number(e),
                        })
                      }
                    />
                  </div>
                </div>
              </div>
            )}
          {constraintToEdit.ingredientGroupId &&
            !constraintToEdit.ingredientCompositionId && (
              <>
                <IngredientGroup proj_id={currentProject?.id}></IngredientGroup>
                <ConstraintForm
                  setConstraintToEdit={setConstraintToEdit}
                  constraint={constraintToEdit}
                />
              </>
            )}
          {constraintToEdit.ingredientCompositionId &&
            !constraintToEdit.ingredientGroupId && (
              <>
                <ConstraintComposition
                  setConstraintToEdit={setConstraintToEdit}
                  constraint={constraintToEdit}
                />
              </>
            )}
          <p style={{ color: 'red' }}>{errorMessage}</p>

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: 10,
            }}
          >
            <div>
              <Button
                size="small"
                type="default"
                onClick={handleConstraintDelete}
              >
                Delete
              </Button>
            </div>

            <div style={{ gap: 5, display: 'flex' }}>
              <Button size="small" onClick={handleCancel}>
                Cancel
              </Button>
              <Button
                size="small"
                type="primary"
                onClick={handleSaveConstraint}
                style={{
                  backgroundColor: '#EF4136',
                }}
              >
                Save Changes
              </Button>
            </div>
          </div>
        </div>
        <Divider style={{ marginTop: 8, marginBottom: 8 }} />
      </div>
    </>
  );
};
